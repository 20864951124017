import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';
import {
  faAngular,
  faCss3Alt,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SiDotnet, SiMicrosoftsqlserver } from 'react-icons/si';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => (
  <div className="app__header app__flex ">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">

        <div className="badge-cmp app__flex">
          <span><p className="p-text " style={{ fontSize: 20 }}>Hello, I am</p></span>
          <div style={{ marginLeft: 20 }}>
            <div >
              <svg width="100%" height="100px">
                <defs>
                  <style>
                    @import url("https://fonts.googleapis.com/css?  family=Lora:400,400i,700,700i");
                  </style>
                </defs>

                <text x="50%" y="60%" text-anchor="middle"  >Vallinayagam</text>
              </svg>
            </div>
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <p className="tag-dev"><span id="type"></span></p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile} alt="profile_bg" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >

<div className="stage-cube-cont">
        <div className="cubespinner">
          <div className="face1">
            <SiDotnet color="#EC4D28"/>
          </div>
          <div className="face2">
            <FontAwesomeIcon icon={faHtml5} color="#F06529" />
          </div>
          <div className="face3">
            <FontAwesomeIcon icon={faCss3Alt} color="#28A4D9" />
          </div>
          <div className="face4">
            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
          </div>
          <div className="face5">
            <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
          </div>
          <div className="face6">
            <SiMicrosoftsqlserver color="#EC4D28"/>
          </div>
        </div>
      </div>

    </motion.div>
  </div>
);

export default AppWrap(Header, 'home');