import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const handleLinkedinClick = () => {
  window.open("https://www.linkedin.com/in/vallinayagam-v");
};


const handleGithubClick = () => {
  window.open("https://github.com/VallinayagamDev");
};

const SocialMedia = () => (
  <div className="app__social">
    <div onClick={handleLinkedinClick}>
      <BsLinkedin />
    </div>
    <div onClick={handleGithubClick}>
      <BsGithub />
    </div>
  </div>
);

export default SocialMedia;